<template>
	<div
		class="container"
		:style="{
			flexDirection: $store.getters.GetIsH5 ? mainContent.h5_direction : mainContent.direction
		}"
	>
		<a v-if="mainContent.link && $store.getters.GetIsH5" :href="mainContent.link" class="h5_btn ke_common_btn"
			>Get started now</a
		>
		<img :src="mainContent.img" alt="" />
		<div class="content">
			<h1
				class="ke_common_title"
				:style="mainContent.titleStyle && mainContent.titleStyle"
				v-html="$store.getters.GetIsH5 ? mainContent.h5_title : mainContent.title"
			></h1>
			<p v-if="mainContent.hasLine || $store.getters.GetIsH5" class="ke_common_line" style="margin: 30px 0"></p>
			<div class="content_outside">
				<div class="content_inner" v-for="item in mainContent.ul_msg" :key="item">
					<span class="circle"></span>
					<p class="ke_common_msg">{{ item }}</p>
				</div>
				<a v-if="mainContent.link" target="_blank" :href="mainContent.link" class="btn ke_common_btn"
					>Get started now</a
				>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		mainContent: {
			type: Object,
			require: true
		}
	}
}
</script>

<style scoped lang="scss">
.container {
	width: 100%;
	display: flex;
	justify-content: space-between;
	img {
		width: 47%;
		height: 100%;
	}
	.content {
		width: 44%;
		.content_outside {
			margin-top: 30px;
			.content_inner {
				position: relative;
				p {
					padding: 10px 0;
					padding-left: 50px;
					line-height: 40px;
				}
				.circle {
					position: absolute;
					top: 22px;
					left: 0;
					display: inline-block;
					width: 10px;
					height: 9.5px;
					border-radius: 50%;
					border: 4px solid var(--ke-primary-red);
				}
			}
			.btn {
				font-size: 25px;
				margin-top: 13px;
				padding: 25px 85px;
			}
		}
	}
}

@media screen and (max-width: 1246px) {
	.container {
		.content {
			width: 100%;
			h1 {
				text-align: center;
			}
			.ke_common_line {
				margin: 20px auto !important;
			}
			.content_outside {
				padding: 0 40px;
				.content_inner {
					p {
						color: #333333;
						font-size: 45px;
						line-height: 50px;
					}
				}
				.btn {
					display: none;
				}
			}
		}
		img {
			width: 90%;
			margin: 0 auto;
			margin-top: 100px;
		}
		.h5_btn {
			font-size: 35px;
			margin: 0 auto;
			width: 400px;
			height: 80px;
			line-height: 80px;
			margin-top: 50px;
		}
	}
}
</style>
