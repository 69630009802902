export const two_employers_config = {
	direction: 'row',
	h5_direction: 'column-reverse',
	img: require('../../../assets/ke-img/Home/employers-bg.webp'),
	title: 'Benefits to<br/> Employers',
	h5_title: 'TiFi for Employers',
	ul_msg: [
		'Offer a no cost, high-value employee.',
		'Automate salary advance requests.',
		'Keep your staff happy; protect your staff from late payments.',
		'Reduce the time it takes for employees to receive pay.',
		'Grow at your own pace, pause your payroll payments and settle at a later date'
	],
	link: 'https://play.google.com/store/apps/details?id=ke.co.tifi.credit'
}
export const two_employees_config = {
	direction: 'row',
	h5_direction: 'column-reverse',
	img: require('../../../assets/ke-img/Home/employees-bg.webp'),
	title: 'Benefits to<br/> Employees',
	h5_title: 'TiFi for Employees',
	ul_msg: [
		'Flexible pay schedules.',
		'Financial safety net.',
		'Reduced stress and anxiety caused by cash flow issues.'
	],
	link: 'https://play.google.com/store/apps/details?id=ke.co.tifi.credit'
}
export const four_page_config = {
	direction: 'row-reverse',
	h5_direction: 'column-reverse',
	titleStyle: { 'padding-top': '50px' },
	img: require('../../../assets/ke-img/Home/four-bg.webp'),
	title: 'Main features of the <span style="color: #D35C58">Earned Wage<br/> Access</span>',
	h5_title: 'TiFi for Employers',
	hasLine: true,
	ul_msg: [
		'0% interest fee.',
		'Low transaction fees.',
		'Fully digital.',
		'Cash disbursed the same day of application.',
		'Upto 75% limit for days worked.'
	]
}

export const three_list_content = [
	{
		h5_title: 'Affordable Finances',
		defaultImg: require('../../../assets/ke-img/Home/three-list/default-affordable.svg'),
		newImg: require('../../../assets/ke-img/Home/three-list/new-affordable.svg'),
		defaultMsg: 'Affordable Finances',
		totalMsg: `<span style="color: #D35C58">Affordable finances</span> to improve the workflow and overall operational efficiency`,
		h5Msg: 'Improve the workflow and<br/> overall operational<br/> efficiency'
	},
	{
		h5_title: 'Digital Transformation',
		defaultImg: require('../../../assets/ke-img/Home/three-list/default-digital.svg'),
		newImg: require('../../../assets/ke-img/Home/three-list/new-digital.svg'),
		defaultMsg: 'Digital Transformation',
		totalMsg: `Leverage the technologies for further <span style="color: #D35C58">digital transformation</span> to enhance productivity`,
		h5Msg: 'Leverage the technologies<br/> for further to enhance<br/> productivity'
	},
	{
		h5_title: 'Core Banking System',
		defaultImg: require('../../../assets/ke-img/Home/three-list/default-connecting.svg'),
		newImg: require('../../../assets/ke-img/Home/three-list/new-connecting.svg'),
		defaultMsg: 'Connecting to a Core Banking System',
		totalMsg: `Connecting to a <span style="color: #D35C58">core banking system</span> to perform all the banking activities in one single platform seamlessly`,
		h5Msg:
			'Connecting to a core banking<br/> system to perform all the<br/> banking activities in one single<br/> platform seamlessly'
	},
	{
		h5_title: 'Dedicated Team',
		defaultImg: require('../../../assets/ke-img/Home/three-list/default-dedicated.svg'),
		newImg: require('../../../assets/ke-img/Home/three-list/new-dedicated.svg'),
		defaultMsg: 'Dedicated Team',
		totalMsg: `<span style="color: #D35C58">Dedicated team</span> to work with you in developing the customized financial solutions`,
		h5Msg: 'We’ll work with you in <br/>developing the customized <br/>financial solutions'
	},
	{
		h5_title: 'Financial Solutions',
		defaultImg: require('../../../assets/ke-img/Home/three-list/default-financial.svg'),
		newImg: require('../../../assets/ke-img/Home/three-list/new-financial.svg'),
		defaultMsg: 'Financial Solutions',
		totalMsg: `<span style="color: #D35C58">Cost-effective</span> and <span style="color: #D35C58">convenient financial solutions</span> to minimize the overall operational costs`,
		h5Msg: 'Cost-effective and convenient <br/>financial solutions to minimize <br/>the overall operational costs'
	},
	{
		h5_title: 'Advanced Technology',
		defaultImg: require('../../../assets/ke-img/Home/three-list/default-advanced.svg'),
		newImg: require('../../../assets/ke-img/Home/three-list/new-advanced.svg'),
		defaultMsg: 'Advanced Technology',
		totalMsg: `Improvement in the process, from application, disbursement to salary deduction with <span style="color: #D35C58">advanced technology</span>`,
		h5Msg: 'Improvement in the process, <br/>from application, disbursement <br/>to salary deduction'
	}
]
