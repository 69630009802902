<template>
	<div>
		<!-- first -->
		<div
			class="home_bg_first wrapper_container bg_center"
			:style="`background-image: url(${!$store.getters.GetIsH5 ? first_bg : h5_first_bg});`"
		>
			<div class="main_content home_content common_first_page">
				<div class="content">
					<h1>Get instant access to earned pay</h1>
					<p class="ke_common_msg">
						When you put your employees in control of their financial well-being, you'll boost job applicants and cut
						turnover.
					</p>
					<a href="https://play.google.com/store/apps/details?id=ke.co.tifi.credit" target="_blank">
						<p class="btn ke_common_btn">Get started now</p>
					</a>
				</div>
			</div>
		</div>
		<!-- two -->
		<div class="home_bg_two wrapper_container">
			<div class="main_content">
				<div class="content_tabs">
					<!-- h5轮播图方式 -->
					<swiper ref="mySwiper" :options="swiperOptions" v-if="$store.getters.GetIsH5">
						<swiper-slide><common-emplo :mainContent="two_employers_config"></common-emplo></swiper-slide>
						<swiper-slide><common-emplo :mainContent="two_employees_config"></common-emplo></swiper-slide>
						<div class="swiper-pagination" slot="pagination"></div>
					</swiper>
					<!-- web菜单方式 -->
					<el-tabs v-model="twoPageActive" stretch v-else>
						<el-tab-pane label="TiFi for Employers" name="Employers"
							><div style="padding-top: 2.5rem">
								<common-emplo :mainContent="two_employers_config"></common-emplo></div
						></el-tab-pane>
						<el-tab-pane label="TiFi for Employees" name="Employees"
							><div style="padding-top: 2.5rem">
								<common-emplo :mainContent="two_employees_config"></common-emplo></div
						></el-tab-pane>
					</el-tabs>
				</div>
			</div>
		</div>
		<!-- three -->
		<div class="home_bg_three wrapper_container bg_center">
			<div class="main_content">
				<h1 v-if="!$store.getters.GetIsH5" class="ke_common_title title">Why you need TiFi</h1>
				<h1 v-else class="ke_common_title h5_title">Main features of the <span>Earned Wage Access</span></h1>
				<p class="line"></p>
				<ul class="list_container">
					<li
						v-for="(item, index) in three_list_content"
						:key="item.defaultMsg"
						@click="switchThreeListActive(index)"
						:class="three_list_active === index ? 'active_item' : 'default_item'"
					>
						<img :src="three_list_active === index ? item.newImg : item.defaultImg" :alt="item.defaultMsg" />
						<p class="li_msg" v-html="three_list_active === index ? item.totalMsg : item.defaultMsg"></p>
						<div v-if="$store.getters.GetIsH5" class="h5_list">
							<h1 v-html="item.h5_title"></h1>
							<p v-if="$store.getters.GetIsH5" v-html="item.h5Msg"></p>
						</div>
						<p v-if="three_list_active === index" class="line_footer"></p>
					</li>
				</ul>
			</div>
		</div>
		<!-- four -->
		<div class="home_bg_four wrapper_container bg_center">
			<div class="main_content">
				<common-emplo :mainContent="four_page_config"></common-emplo>
			</div>
		</div>
		<!-- five -->
		<div class="home_bg_five wrapper_container">
			<div class="main_content">
				<h1 class="ke_common_title title">Some of our partners</h1>
				<p class="ke_common_msg msg">Join the employers transforming peoples’lives, by providing power over pay.</p>
				<img
					v-if="!$store.getters.GetIsH5"
					class="logo_list"
					src="~@/assets/ke-img/Home/five-logo/list-logo.webp"
					alt="list-logo"
				/>
				<div v-else>
					<el-carousel trigger="click" height="14rem" v-if="$store.getters.GetIsH5" :autoplay="true" arrow="never">
						<el-carousel-item><img src="~@/assets/ke-img/Home/five-logo/delsco.webp" alt="delsco" /></el-carousel-item>
						<el-carousel-item><img src="~@/assets/ke-img/Home/five-logo/retail.webp" alt="retail" /></el-carousel-item>
						<el-carousel-item><img src="~@/assets/ke-img/Home/five-logo/accra.webp" alt="accra" /></el-carousel-item>
						<el-carousel-item><img src="~@/assets/ke-img/Home/five-logo/ruby.webp" alt="ruby" /></el-carousel-item>
						<el-carousel-item><img src="~@/assets/ke-img/Home/five-logo/coseke.webp" alt="coseke" /></el-carousel-item>
						<el-carousel-item><img src="~@/assets/ke-img/Home/five-logo/gaid.webp" alt="gaid" /></el-carousel-item>
						<el-carousel-item
							><img src="~@/assets/ke-img/Home/five-logo/palm.webp" style="width: 100%; height: 80%" alt="palm"
						/></el-carousel-item>
						<el-carousel-item><img src="~@/assets/ke-img/Home/five-logo/jenga.webp" alt="jenga" /></el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CommonEmplo from '../Components/common_emplo'
import { two_employers_config, two_employees_config, three_list_content, four_page_config } from './home.config'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
	components: {
		CommonEmplo,
		Swiper,
		SwiperSlide
	},
	data() {
		return {
			twoPageActive: 'Employers',
			two_employers_config,
			two_employees_config,
			three_list_content,
			four_page_config,
			three_list_active: '',
			homeEmail: '',
			first_bg: require('@/assets/ke-img/Home/first-bg.webp'),
			h5_first_bg: require('@/assets/ke-img/Home/h5-first-bg.webp'),
			swiperOptions: {
				pagination: {
					el: '.swiper-pagination',
					clickable: true
				},
				autoplay: {
					delay: 2000
				},
				loop: true
			}
		}
	},
	methods: {
		twoPageChange() {
			// 默认展示雇主信息，5s切换为雇员信息
			setTimeout(() => {
				this.twoPageActive = 'Employees'
			}, 5000)
		},
		switchThreeListActive(index) {
			if (!this.$store.getters.GetIsH5) {
				this.three_list_active = index
			}
		}
	},
	mounted() {
		this.twoPageChange()
		new Swiper('.swiper-container', {
			loop: true,
			// 如果需要分页器
			pagination: '.swiper-pagination'
		})
	}
}
</script>

<style scoped lang="scss">
.home_bg_first {
	height: 1190px;
	.home_content {
		.content {
			color: #fff;
			h1 {
				font-size: 95px;
				width: 65%;
				font-weight: bold;
				text-shadow: 0px 4px rgba(0, 0, 0, 0.25);
			}
			p {
				width: 45%;
				padding: 0;
				margin: 60px 0;
				line-height: 40px;
			}
			.btn {
				width: 200px;
				height: 50px;
				line-height: 50px;
			}
		}
	}
}
.home_bg_two {
	background-color: #fff;
	// height: 900px;
	padding: 140px 0;
	.main_content {
		.content_tabs {
			margin: 0 auto;
			.el-tabs {
				::v-deep .el-tabs__header {
					width: 75%;
					margin: 0 auto;
					.el-tabs__nav-wrap {
						.el-tabs__nav-scroll {
							.el-tabs__nav {
								.el-tabs__item {
									height: 50px;
									font-size: 27px;
									font-family: Montserrat;
									font-weight: bold;
									color: #d4d4d4;
								}
								.el-tabs__item.is-active {
									color: #000;
								}
								.el-tabs__active-bar {
									// tabs的滑动条
									height: 3px;
									background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
										linear-gradient(0deg, #333333, #333333);
								}
							}
						}
					}
				}
			}
		}
	}
}
.home_bg_three {
	background-image: url('~@/assets/ke-img/Home/three-bg.webp');
	height: 950px;
	background-color: #203d6a;
	.main_content {
		.title {
			padding-top: 100px;
			text-align: center;
			color: #fff;
		}
		.line {
			padding: 4px 10px;
			height: 1px;
			background-color: #d35c58;
			width: 130px;
			margin: 30px auto;
			margin-bottom: 30px;
		}
		.list_container {
			display: flex;
			flex-wrap: wrap;
			li {
				// overflow: hidden;
				cursor: pointer;
				margin-top: 40px;
				text-align: center;
				width: 33.33%;
				height: 280px;
				p {
					color: #fff;
				}
			}
			.default_item {
				img {
					margin: 0 auto;
					width: 150px;
					height: 150px;
				}
				.li_msg {
					width: 60%;
					margin: 0 auto;
					margin-top: 15px;
					font-size: 25px;
				}
			}
			.active_item {
				position: relative;
				background-color: #e2edff;
				border-radius: 10px;
				img {
					padding: 20px;
					margin: 0 auto;
					width: 50px;
					height: 50px;
				}
				.li_msg {
					width: 100%;
					color: #333333;
					margin: 0 auto;
					margin-top: 10px;
					font-size: 25px;
					line-height: 30px;
					padding-bottom: 10px;
				}
				.line_footer {
					position: absolute;
					bottom: 10px;
					left: 50%;
					transform: translateX(-50%);
					background-color: var(--ke-primary-red);
					width: 30%;
					padding: 3px;
				}
			}
		}
	}
}
.home_bg_four {
	background-color: #e2edff;
	.main_content {
		padding: 100px 0;
	}
}
.home_bg_five {
	padding: 180px 0;
	background-color: #fff;
	.main_content {
		.title {
			text-align: center;
		}
		.msg {
			font-style: SemiBold;
			margin-top: 25px;
			text-align: center;
		}
		.logo_list {
			margin-top: 150px;
		}
	}
}

@media screen and (max-width: 1246px) {
	::v-deep .el-carousel {
		.el-carousel__indicators--horizontal {
			.el-carousel__indicator--horizontal {
				button {
					height: 20px;
					width: 20px;
					border-radius: 50%;
					background-color: var(--ke-primary-red);
				}
			}
		}
	}
	.home_bg_first {
		height: 950px;
		.main_content {
			padding-top: 400px;
			.content {
				text-align: center;
				h1 {
					font-size: 60px;
					width: 100%;
				}
				p {
					width: 100%;
				}
				.btn {
					width: 320px;
					height: 75px;
					line-height: 75px;
					margin: 0 auto;
					font-size: 30px;
				}
			}
		}
	}
	.home_bg_two {
		padding: 90px 0;
		.main_content {
			width: 85%;
			.content_tabs {
				.swiper-container {
					padding: 80px 0;
					::v-deep .swiper-pagination {
						.swiper-pagination-bullet {
							width: 20px;
							height: 20px;
							background-color: #eab1af;
						}
						.swiper-pagination-bullet-active {
							background-color: #d35c58;
						}
					}
				}
			}
		}
	}
	.home_bg_three {
		height: 3700px;
		background-color: var(--ke-primary-deep-blue);
		background-image: none;
		.main_content {
			.h5_title {
				width: 99%;
				padding-top: 100px;
				text-align: center;
				color: #fff;
				span {
					color: var(--ke-primary-red);
				}
			}
			.list_container {
				li {
					pointer-events: none;
					cursor: default;
					margin: 130px 0;
					width: 100%;
					.li_msg {
						display: none;
					}
					.h5_list {
						h1 {
							font-size: 35px;
							margin: 30px 0;
							color: #fff;
						}
						p {
							font-size: 30px;
							line-height: 50px;
						}
					}
				}
			}
		}
	}
	.home_bg_four {
		.main_content {
			.container {
				padding-bottom: 100px;
			}
		}
	}
	.home_bg_five {
		.main_content {
			p {
				font-size: 30px;
				line-height: 45px;
				color: #333;
				margin-bottom: 100px;
			}
		}
	}
}
</style>
